import React from "react";
import { Container, Nav, Navbar, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useAuth } from "../../hooks/useAuth";
import toast from "react-hot-toast";

const Header = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
    navigate("/");
    toast.success("You have been logged out successfully!", {
      duration: 2000, // Adjust the duration as needed
    });
    // Cookies.remove("username");
    // Cookies.remove("password");
    // localStorage.removeItem("rememberMe");
  };

  return (
    <header>
      <Navbar expand="lg" className="py-3">
        <Container fluid>
          <Navbar.Brand href="#">Admin Dashboard</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Link href=" https://www.forevermems.com/" target="_blank">
                About
              </Nav.Link>
              <Nav.Link
                href="https://www.forevermems.com/pricing/"
                target="_blank"
              >
                Pricing
              </Nav.Link>
              <Nav.Link href="https://portal.forevermems.com/" target="_blank">
                Portal Link
              </Nav.Link>
            </Nav>
            <Nav>
              <Button variant="light" onClick={handleLogout}>
                Logout
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
