import axios from "axios";
export const authService = {
  sendForgotPasswordMail,
  adminLogin,
  ownerLogin,
  viewerLogin,
  executorLogin,
};
// NOT USED
async function sendForgotPasswordMail(data) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/forget-passoword-link`,
    data,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
// FOR SUPER ADMIN LOGIN
async function adminLogin(adminData) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/admin-login`,
    adminData,
    {
      headers: { "Content-Type": "application/json" },
    }
  );
}
// FOR LOGIN AS OWNER
async function ownerLogin(ownerEmail, token) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/admin-login-as-owner`,
    ownerEmail,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
// FOR LOGIN AS VIEWER
async function viewerLogin(viewerPayload, token) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/admin-login-as-viewer`,
    viewerPayload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
// FOR LOGIN AS EXECUTOR
async function executorLogin(executorPayload, token) {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/admin-login-as-executer`,
    executorPayload,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
}
