import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Card,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { authService } from "../../_services";
import { toast } from "react-hot-toast";

import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { useAuth } from "../../hooks/useAuth";

const LoginPage = () => {
  const { login } = useAuth(); // Use the updated useAuth hook to manage authentication

  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  // REMEMBER ME
  useEffect(() => {
    const rememberMeState = localStorage.getItem("rememberMe") === "true";
    setRememberMe(rememberMeState);
    const { username, password } = getRememberMeCookie();
    formik.setValues({ userName: username, password });
  }, []);

  const setRememberMeCookie = (username, password) => {
    if (rememberMe) {
      Cookies.set("username", username, { expires: 7 });
      Cookies.set("password", password, { expires: 7 });
    } else {
      Cookies.remove("username");
      Cookies.remove("password");
    }
  };

  const getRememberMeCookie = () => {
    const username = Cookies.get("username") || "";
    const password = Cookies.get("password") || "";
    return { username, password };
  };

  const handleRememberMeChange = (e) => {
    const isChecked = e.target.checked;
    setRememberMe(isChecked);
    localStorage.setItem("rememberMe", isChecked);
  };
  // SUBMIT LOGIN DATA
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setIsLoading(true);
    toast.success(
      "Scanning the forevermems for your credentials. Please wait...",
      { duration: 2500 }
    );
    try {
      const adminData = {
        email: values.userName,
        password: values.password,
      };
      const response = await authService.adminLogin(adminData);
      if (response?.data?.status === true) {
        const { data } = response.data;
        login(data.token); // Use the login function to set the authentication token
        toast.success("Navigating to your admin dashboard...", {
          duration: 1500,
        });
        toast.success("Login successful! Welcome aboard!", { duration: 1000 });
        setRememberMeCookie(values.userName, values.password);
        setTimeout(() => {
          navigate("/owner-list");
        }, 1000);
      }
    } catch (error) {
      if (
        error?.response?.data?.status === true &&
        error?.response?.data?.statusCode === 401
      ) {
        setErrors({ password: "incorrect Password" });
      }
      toast.error("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const schema = yup.object().shape({
    userName: yup.string().required("Please Enter Your Email Address"),
    password: yup.string().required("Please Enter Your Password"),
  });

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <>
      {isLoading && <Loader />}
      <Container className="py-xl-5 py-2">
        <Row className="justify-content-center text-center">
          <Col xl={8} lg={8} md={8}>
            <img alt="" className="mb-2" width="150" src="logo.png" />
            <h4 className="mt-2">Log In as an : </h4>
            <h4 className="mt-2">Admin</h4>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xl={4} lg={8} md={8}>
            <Form
              className="mt-5 signup-section"
              onSubmit={formik.handleSubmit}
            >
              <InputGroup className="gap-2 login-input-section m-0">
                <Form.Control
                  className="w-100 min-height-60"
                  placeholder="Enter Email Address"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.userName}
                  name="userName"
                />
                <Form.Text className="text-danger">
                  {formik.touched.userName && formik.errors.userName}
                </Form.Text>
                <Form.Control
                  className="w-100 min-height-60"
                  placeholder="Enter password"
                  type="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  name="password"
                />
                <Form.Text className="text-danger">
                  {formik.touched.password && formik.errors.password}
                </Form.Text>
              </InputGroup>
              <div className="form-check mt-0 d-flex justify-content-end">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
                <label className="form-check-label" htmlFor="rememberMe">
                  Remember Me
                </label>
              </div>
              <small className="text-end d-block">
                <b>
                  <Link to="/forgot-password">
                    <i>Forgot Password</i>
                  </Link>
                </b>
              </small>
              <div className="d-flex gap-3 flex-wrap mt-4">
                <Button
                  className="w-100 min-height-60"
                  variant="primary"
                  type="submit"
                >
                  SIGN IN
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginPage;
