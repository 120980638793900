import React from "react";

const Loader = () => {
    return (
        <>
        <div className="d-flex align-items-center justify-content-center w-100 h-100 absolute loader-container">

        <span className="loader"></span>
        </div>
        </>
    )
}
export default Loader;