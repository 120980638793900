import React, { useState } from "react";
import Header from "../Header";
import SideBar from "../SideBar";
import { useLocation } from "react-router-dom";
import LoginPage from "../../pages/login";
import ForgotPassword from "../../pages/forgot-password";

const SiteLayout = ({ children }) => {
  const location = useLocation();

  const { pathname } = location;
  const [addClass, setAddClass] = useState(true);

  const handleClick = () => {
    setAddClass(!addClass);
  };

  return (
    <>
      {pathname === "/" ? (
        <LoginPage />
      ) : pathname === "/forgot-password" ? (
        <ForgotPassword />
      ) : (
        <div className="dashboard-layout">
          <div className={`dashboard-wrapper ${addClass ? "sidebar-open" : ""}`}>
            <SideBar
              handleClick={handleClick}
              addClass={addClass}
              setAddClass={setAddClass}
            />

            <div className="dashboard-main-content px-3">
              <Header />
              <main className="dashboard-inner-content-wrapper mt-4">
                {children}
              </main>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SiteLayout;
