import { useState } from "react";

export const useAuth = () => {
  const [authToken, setAuthToken] = useState(null);

  const login = (token) => {
    setAuthToken(token);
  };

  const logout = () => {
    setAuthToken(null); // Clear the authentication token
  };

  return { authToken, login, logout };
};
