import { Toaster } from 'react-hot-toast';
import AppRouter from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
  <>
  <AppRouter/>
  <Toaster position="top-right" />
  </>
  );
}

export default App;
