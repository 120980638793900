/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const SideBar = ({ handleClick, addClass, setAddClass }) => {
  const location = useLocation();

  const { pathname, search, state } = location;
  useEffect(() => {
    AdminLinks();
  }, []);

  const AdminLinks = () => {
    return (
      <>
        <div className="sidebar-list">
          <ul>
            <li>
              <NavLink to={`/owner-list`}>
                <img src="/assets/sidebar-icon/user.png" alt="Customers" />{" "}
                Owners
              </NavLink>
            </li>{" "}
            <li>
              <NavLink to={`/viewer-list`}>
                <img src="/assets/sidebar-icon/user.png" alt="Customers" />{" "}
                Viewers
              </NavLink>
            </li>{" "}
            <li>
              <NavLink to={`/executor-list`}>
                <img src="/assets/sidebar-icon/user.png" alt="Customers" />{" "}
                Executors
              </NavLink>
            </li>{" "}
          </ul>
          <ul></ul>
        </div>
        {/* ) */}
      </>
    );
  };

  return (
    <>
      {
        <aside className="py-3">
          <div className="text-center">
            <img alt="" className="sidebarlogo" src="/logo.png" />
          </div>
          <>
            <AdminLinks />
          </>
        </aside>
      }
    </>
  );
};

export default SideBar;
