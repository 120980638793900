/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { authService } from "../../_services";
import { toast } from "react-hot-toast";
import { useState } from "react";
import SuccessMessage from "../../components/SuccessMessage";

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  let schema = {};
  schema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address format")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setIsLoading(true);
      let userData = {};
      userData = {
        email: values.email,
      };
      authService
        .sendForgotPasswordMail(userData)
        .then((response) => {
          if (response?.data?.status === true) {
            toast.success(
              "A link to reset your password has been emailed to you check your inbox."
            );
            setSuccess(true);
          }
        })
        .catch((error) => {
          console.log("ERROR", error);
          toast.error("Unable to reset your password");
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });
  return (
    <>
      <Container className="py-xl-5 py-2">
        <Row className="justify-content-center text-center">
          <Col xl={8} lg={8} md={8}>
            <img alt="" className="mb-2" width="150" src="logo.png" />
            <h4 className="login-forevermems">Forgot Your Password?</h4>
            <h3 className="mt-5 fst-normal">
              We will email instructions to your provided email address
              <br />
              to reset it
            </h3>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xl={4} lg={8} md={8}>
            {success && (
              <SuccessMessage message="A link to reset your password has been emailed to you check your inbox" />
            )}
            <Form
              className="mt-5 signup-section"
              onSubmit={formik.handleSubmit}
            >
              <InputGroup className="gap-2 login-input-section">
                <Form.Control
                  className="w-100 min-height-60"
                  placeholder="Email Address ..."
                  type="email"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name="email"
                />
                <div className="d-flex align-items-center w-100">
                  <Form.Text className="text-danger w-50">
                    {formik.touched.email && formik.errors.email}
                  </Form.Text>
                </div>
              </InputGroup>

              <div className="d-flex gap-3 flex-wrap mt-4">
                <Button
                  className="w-100 min-height-60"
                  variant="primary"
                  type="submit"
                >
                  {isLoading ? (
                    <>
                      <Spinner size="sm" /> Please wait..
                    </>
                  ) : (
                    "RESET PASSWORD"
                  )}
                </Button>
              </div>
              <p className="text-center mt-5 ">
                <a href="/">
                  <b>Return to log In</b>
                </a>
              </p>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
