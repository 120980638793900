import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import { lazy } from "react";
import Spinner from "react-bootstrap/Spinner";
import SiteLayout from "../components/Layouts/SiteLayout";
const AdminLoginPage = lazy(() => import("../pages/login"));
const NotFound = lazy(() => import("../pages/not-found"));
const ForgotPassword = lazy(() => import("../pages/forgot-password"));
const OwnerList = lazy(() => import("../pages/owners"));
const ViewerList = lazy(() => import("../pages/viewers"));
const ExecutorList = lazy(() => import("../pages/executors"));

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <AdminLoginPage />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/owner-list"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <OwnerList />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/viewer-list"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <ViewerList />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/executor-list"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <ExecutorList />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <ForgotPassword />
              </Suspense>
            </SiteLayout>
          }
        />
        <Route
          path="*"
          element={
            <SiteLayout>
              <Suspense
                fallback={
                  <div className="loading-loader-section">
                    <Spinner animation="border" variant="primary" />
                  </div>
                }
              >
                <NotFound />
              </Suspense>
            </SiteLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
